import React, { useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import CustomImage from '../../atoms/CustomImage';
import Button from '../../atoms/Button';
import Modal from '../../atoms/Modal';
import PlayButton from '../../atoms/PlayButton';
import YouTubeEmbed from '../../atoms/YouTubeEmbed';
import LinkStandard from '../../atoms/LinkStandard';
import Styled from './index.styled';
import Browser from '../../../lib/browser';

type ImageTypes = {
	src: string;
	alt: string;
	imageType?: string;
};

type LinkTypes = {
	url: string;
	title: string;
	isAnchorLink?: boolean;
	dataGACategory: string;
	dataGAAction: string;
	dataGALabel: string;
};

type EmbedTypes = {
	youtubeEmbed: string;
	dataGACategory: string;
	dataGAAction: string;
	dataGALabel: string;
};

type MultiOptionMediaCtaTypes = {
	image: ImageTypes;
	title: string;
	keyPoints: string[];
	link?: LinkTypes;
	linkType?: string;
	linkIcon?: string;
	embed?: EmbedTypes;
	hiddenMobileImage?: boolean;
	applySecondaryBackground?: boolean;
	imageSwapVariation?: boolean;
	rightToLeft?: boolean;
	anchorId?: string;
	desktopImageOnRight?: boolean;
	mobileImageOnBottom?: boolean;
	desktopImageShape?: string;
	hiddenImageShadow?: boolean;
	backgroundColour?: string;
	richCopy?: Document;
};

const renderBody = (item: string, index: number) => (
	<Styled.MultiOptionMediaCta__Body key={index}>{item}</Styled.MultiOptionMediaCta__Body>
);

const MultiOptionMediaCta = ({
	image,
	title,
	keyPoints,
	link,
	linkType,
	linkIcon,
	embed,
	hiddenMobileImage,
	applySecondaryBackground, // deprecated but left in for components already built in CMS
	imageSwapVariation, // same
	anchorId,
	desktopImageOnRight,
	mobileImageOnBottom,
	desktopImageShape,
	hiddenImageShadow,
	backgroundColour,
	richCopy,
}: MultiOptionMediaCtaTypes) => {
	const [show, setShow] = useState(false);

	const showModal = (event: Event) => {
		event.preventDefault();
		setShow(true);
	};

	const getMultiOptionMediaCtaContainerComponent = applyBackground => {
		const exists = applyBackground !== null && applyBackground !== false;

		switch (exists) {
			case true:
				return Styled.MultiOptionMediaCtaBackground;
			default:
				return Styled.MultiOptionMediaCta;
		}
	};

	const MultiOptionMediaCtaContainerComponent = getMultiOptionMediaCtaContainerComponent(
		applySecondaryBackground || backgroundColour
	);

	return (
		<>
			<MultiOptionMediaCtaContainerComponent
				as="section"
				id={anchorId || ''}
				rightToLeft={imageSwapVariation || desktopImageOnRight}
				alignTop={imageSwapVariation} // doesn't do anything?
				backgroundColour={
					backgroundColour || (applySecondaryBackground ? 'secondary' : null)
				}
			>
				{image && !embed && (
					<Styled.MultiOptionMediaCta__ImageWrapper
						hiddenMobileImage={
							hiddenMobileImage || imageSwapVariation || mobileImageOnBottom
						}
						imageSwapVariation={imageSwapVariation}
						portrait={imageSwapVariation || desktopImageShape === 'portrait'}
						hiddenImageShadow={hiddenImageShadow}
						desktopImageShape={desktopImageShape}
						mobileImageOnBottom={mobileImageOnBottom}
					>
						<CustomImage {...image} width="300" height="200" />
					</Styled.MultiOptionMediaCta__ImageWrapper>
				)}

				{	/* {embed && !image && (
					<Styled.MultiOptionMediaCta__ImageWrapper
						hiddenMobileImage={
							hiddenMobileImage || imageSwapVariation || mobileImageOnBottom
						}
						imageSwapVariation={imageSwapVariation}
						portrait={imageSwapVariation || desktopImageShape === 'portrait'}
						hiddenImageShadow={hiddenImageShadow}
						desktopImageShape={desktopImageShape}
						mobileImageOnBottom={mobileImageOnBottom}
					>
						<YouTubeEmbed embedLink={embed.youtubeEmbed} />
					</Styled.MultiOptionMediaCta__ImageWrapper>
					)} */ }

				{image && embed && (
					<Styled.MultiOptionMediaCta__ImageWrapper
						video
						hiddenMobileImage={hiddenMobileImage}
						hiddenImageShadow={hiddenImageShadow}
						desktopImageShape={desktopImageShape}
					>
						<CustomImage {...image} width="300" height="210" />
						<Styled.PlayButtonWrapper>
							<PlayButton
								onClick={() => setShow(true)}
								dataGACategory={embed.dataGACategory}
								dataGAAction={embed.dataGAAction}
								dataGALabel={embed.dataGALabel}
								ariaLabel="play-embeded-video"
							/>
						</Styled.PlayButtonWrapper>
					</Styled.MultiOptionMediaCta__ImageWrapper>
				)}

				<Styled.MultiOptionMediaCta__TextWrapper>
					<Styled.MultiOptionMediaCta__Title
						as="h2"
						stopWrapping={imageSwapVariation}
						mobileImageOnBottom={mobileImageOnBottom}
						hiddenMobileImage={hiddenMobileImage}
					>
						{title}
					</Styled.MultiOptionMediaCta__Title>

					{keyPoints && keyPoints.map(renderBody)}

					{richCopy && (
						<Styled.MultiOptionMediaCta__RichCopy as="div">
							{documentToReactComponents(richCopy, {
								renderText: text =>
									text.split('\n').flatMap((copy, i) => [i > 0 && <br />, copy]),
							})}
						</Styled.MultiOptionMediaCta__RichCopy>
					)}

					{link && linkType === 'button' && (
						<Styled.MultiOptionMediaCta__Button>
							<Button
								buttonLink={link.url}
								buttonText={link.title}
								buttonCategory={link.dataGACategory}
								buttonAction={link.dataGAAction}
								buttonLabel={link.dataGALabel}
								buttonType="primary"
								target={link.isAnchorLink ? '_blank' : undefined}
								iconName={linkIcon}
								callback={event => Browser.handleClick(event, link.url)}
							/>
						</Styled.MultiOptionMediaCta__Button>
					)}

					{link && embed && linkType === 'video-inline' && (
						<Styled.MultiOptionMediaCta__video__link>
							<Styled.PlayButton__wrapper>
								<PlayButton
									onClick={() => setShow(true)}
									dataGACategory={embed.dataGACategory}
									dataGAAction={embed.dataGAAction}
									dataGALabel={embed.dataGALabel}
									ariaLabel="play-inline-video"
								/>
							</Styled.PlayButton__wrapper>
							<LinkStandard
								{...link}
								callBack={event => showModal(event)}
								showIcon={false}
							/>
						</Styled.MultiOptionMediaCta__video__link>
					)}
				</Styled.MultiOptionMediaCta__TextWrapper>
				{image && (mobileImageOnBottom || imageSwapVariation) && !embed && (
					<Styled.MultiOptionMediaCta__ImageWrapper
						hiddenMobileImage={hiddenMobileImage}
						imageSwapVariation={imageSwapVariation}
						portrait={false}
						hideOnDesktop
						hiddenImageShadow={hiddenImageShadow}
						mobileImageOnBottom={mobileImageOnBottom}
					>
						<CustomImage {...image} width="300" height="200" />
					</Styled.MultiOptionMediaCta__ImageWrapper>
				)}
			</MultiOptionMediaCtaContainerComponent>
			{image && embed && (
				<Modal show={show} setShow={setShow}>
					<YouTubeEmbed embedLink={embed.youtubeEmbed} />
				</Modal>
			)}
		</>
	);
};

export default MultiOptionMediaCta;
