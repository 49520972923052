import styled, { css } from 'styled-components';
import { Grid } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, spacing, margins } from '../../../styles/settings/spacing';
import { weight } from '../../../styles/settings/typography';
import { above, below } from '../../../styles/tools/media';
import aspectRatio from '../../../styles/tools/aspectRatio';
import { smBodyLight, xlTitleRegular } from '../../atoms/Typography/index.styled';

type ContainerConfig = {
    rightToLeft?: boolean;
    alignTop?: boolean;
    backgroundColour?: string | boolean | null;
};

const MultiOptionMediaCta = styled(Grid)<ContainerConfig>`
    margin-top: ${spacing[48]};
    margin-bottom: ${spacing[48]};
    align-items: ${(props) => (props.alignTop ? 'start' : 'center')};

    ${above(
        breakpoints[960],
        () => css`
            align-items: center;
            margin-top: ${spacing[64]};
            margin-bottom: ${spacing[64]};
        `
    )}

    ${above(
        breakpoints[1440],
        () => css`
            margin-top: ${spacing[80]};
            margin-bottom: ${spacing[80]};
        `
    )}

	${(props) =>
        props.rightToLeft &&
        css`
            direction: rtl;
        `};
`;

const MultiOptionMediaCtaBackground = styled(Grid)<ContainerConfig>`
    padding-top: ${spacing[48]};
    padding-bottom: ${spacing[48]};
    background-color: ${(props) =>
        props.backgroundColour != null
            ? `var(--${props.backgroundColour}Background)`
            : 'var(--secondaryBackground)'};
    max-width: none;
    margin-right: 0px;
    margin-left: 0px;

    ${above(
        breakpoints[960],
        () => css`
            align-items: center;
            padding-top: ${spacing[64]};
            padding-bottom: ${spacing[64]};
            max-width: none;
            margin-right: 0px;
            margin-left: 0px;
        `
    )}

    ${above(
        breakpoints[1440],
        () => css`
            padding-top: ${spacing[80]};
            padding-bottom: ${spacing[80]};
            max-width: none;
            margin-right: 0px;
            margin-left: 0px;
        `
    )}

	${(props) =>
        props.rightToLeft &&
        css`
            direction: rtl;
        `};
`;

type ImageWrapperProps = {
    hiddenMobileImage?: boolean;
    video?: boolean;
    imageSwapVariation?: boolean;
    portrait?: boolean;
    hideOnDesktop?: boolean;
    desktopImageShape?: string;
    hiddenImageShadow?: boolean;
    mobileImageOnBottom?: boolean;
};

const MultiOptionMediaCta__ImageWrapper = styled.div<ImageWrapperProps>`
    position: relative;
    left: ${(props) => props.video || props.imageSwapVariation || props.mobileImageOnBottom ? '0' : margins[16]};
    grid-column: 1 / -1;
    margin-bottom: ${(props) =>
        props.mobileImageOnBottom || props.imageSwapVariation
            ? props.hiddenImageShadow
                ? '0'
                : gap[16]
            : gap[48]};
    margin-top: ${(props) =>
        props.mobileImageOnBottom || props.imageSwapVariation ? margins[48] : '0'};

    & > picture {
        padding-top: ${aspectRatio('3:2')};

        box-shadow: ${(props: ImageWrapperProps) =>
            props.hiddenImageShadow ? 'none' : `${gap[16]} ${gap[16]} 0 0 var(--primaryAccent)`};
    }
	
    ${above(
        breakpoints[600],
        () => css<ImageWrapperProps>`
            margin-bottom: ${(props) =>
                props.mobileImageOnBottom ? (props.hiddenImageShadow ? '0' : gap[16]) : gap[64]};
            left: ${(props) => (props.hideOnDesktop ? '0' : margins[24])};
        `
    )}

    ${(props) =>
        props.hideOnDesktop &&
        css`
            ${above(
                breakpoints[960],
                () => css`
                    display: none;
                `
            )}
        `}

	${(props) =>
        props.video &&
        css`
            ${above(
                breakpoints[600],
                () => css`
                    left: 0;
                `
            )}
        `}

	${props => above(
        breakpoints[960],
        () => css`
            left: 0;
            grid-column: 1 / span 5;
            margin-bottom: ${(props.hiddenImageShadow ? '0' : gap[16])};
            margin-top: 0;

            & > picture {
                box-shadow: ${props.hiddenImageShadow
                        ? 'none'
                        : `${gap[24]} ${gap[24]} 0 0 'var(--primaryAccent)'`};
                padding-top: ${props.portrait
                        ? aspectRatio('2:3')
                        : props.desktopImageShape === 'square'
                        ? aspectRatio('1:1')
                        : aspectRatio('3:2')};
            }
        `
    )}

	${props => above(
        breakpoints[1440],
        () => css`
            & > picture {
                box-shadow: ${props.hiddenImageShadow
                        ? 'none'
                        : `${gap[32]} ${gap[32]} 0 0 'var(--primaryAccent)'`};
            }
        `
    )}

	${(props) =>
        props.hiddenMobileImage &&
        css`
            ${below(
                breakpoints[960],
                () => css`
                    display: none;
                `
            )}
        `}
`;

const MultiOptionMediaCta__TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-column: 1 / -1;
    direction: ltr;

    ${above(
        breakpoints[960],
        () => css`
            grid-column: 7 / -1;
        `
    )}
`;
type TitleConfig = {
    stopWrapping?: boolean;
    mobileImageOnBottom?: boolean;
    hiddenMobileImage?: boolean;
};
const MultiOptionMediaCta__Title = styled(xlTitleRegular)<TitleConfig>`
    margin-bottom: ${gap[24]};
    color: var(--primaryForeground);
    margin-top: ${(props) =>
        props.mobileImageOnBottom || props.hiddenMobileImage ? '-14px' : '0'};

    ${above(
        breakpoints[960],
        () => css<TitleConfig>`
            margin-bottom: ${gap[32]};
            white-space: ${(props) => (props.stopWrapping ? 'nowrap' : 'normal')};
        `
    )}
`;

const MultiOptionMediaCta__Body = styled(smBodyLight)`
    margin-bottom: ${gap[32]};

    &:last-of-type {
        margin-bottom: ${gap[0]};
    }

    ${above(
        breakpoints[960],
        () => css`
            margin-bottom: ${gap[40]};
        `
    )}
`;

const MultiOptionMediaCta__RichCopy = styled(smBodyLight)`
    color: var(--black);

    & a {
        color: var(--primaryForeground);
        text-decoration: none;
    }

    & p {
        margin-bottom: ${gap[32]};
    }

    p:empty {
        display: none;
    }

    p:last-of-type {
        margin-bottom: ${gap[0]};
    }

    & b {
        font-weight: ${weight[400]};
    }

    & h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: ${weight[400]};
        margin-bottom: ${gap[12]};
    }

    & ul {
        list-style-type: square;
        padding-left: ${gap[24]};
    }

    & ol {
        padding-left: ${gap[24]};
    }

    & li::marker {
        color: var(--primaryAccent);
    }

    li > p {
        margin-bottom: 0px;
    }

    ${above(
        breakpoints[1280],
        () => css`
            & p {
                margin-bottom: ${gap[32]};
            }

            & h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin-bottom: ${gap[16]};
            }
        `
    )}
`;

const MultiOptionMediaCta__Button = styled.div`
    margin-top: ${gap[32]};

    ${above(
        breakpoints[960],
        () => css`
            margin-top: ${gap[40]};
        `
    )}
`;

const PlayButtonWrapper = styled.div`
    position: absolute;
    width: 48px;
    height: 48px;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);

    ${above(
        breakpoints[960],
        () => css`
            width: 80px;
            height: 80px;
        `
    )}
`;

const PlayButton__wrapper = styled.div`
    min-height: 40px;
    min-width: 40px;
    height: 40px;
    width: 40px;
    margin-right: 16px;
    margin-top: 8px;

    ${above(
        breakpoints[960],
        () => css`
            height: 48px;
            width: 48px;
            margin-right: 32px;
            margin-top: 0;
        `
    )}
`;

const MultiOptionMediaCta__video__link = styled.div`
    display: flex;
    margin-top: ${gap[32]};

    ${above(
        breakpoints[960],
        () => css`
            margin-top: ${gap[40]};
        `
    )}
`;

export default {
    MultiOptionMediaCta,
    MultiOptionMediaCtaBackground,
    MultiOptionMediaCta__ImageWrapper,
    MultiOptionMediaCta__TextWrapper,
    MultiOptionMediaCta__Title,
    MultiOptionMediaCta__Body,
    MultiOptionMediaCta__Button,
    PlayButtonWrapper,
    MultiOptionMediaCta__video__link,
    PlayButton__wrapper,
    MultiOptionMediaCta__RichCopy,
};
